'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import ErrorMessageClient from '~/components/customComponents/error-message/ErrorMessageClient';

/**
 * Global error boundary component that catches errors in the app and sends them to Sentry for tracking
 * Error boundaries must be Client Components. This is a limitation of Next.js.
 * @param error - Error object that caused the error boundary to trigger
 * @param reset - Function to reset the error boundary
 */
const GlobalError = ({ error, reset }: { error: Error; reset: () => void }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  // global-error must include html and body tags
  return (
    <html>
      <body>
        <ErrorMessageClient
          titleKey="global.error.title"
          showRefreshBtn
          reset={reset}
        />
      </body>
    </html>
  );
};

export default GlobalError;
