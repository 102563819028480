'use client';

import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import { colors, spacing } from '~/utils/tailwindUtils';

type ButtonProps = {
  text: string;
  buttonClassName?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right' | 'top' | 'bottom';
  iconClassName?: string;
  iconColor?: string;
  textClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  text,
  icon,
  iconClassName,
  iconPosition = 'left',
  iconColor = colors.white,
  buttonClassName,
  textClassName,
  disabled,
  isLoading,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={twMerge(
        'flex items-center justify-center px-3 py-2.5 gap-2 bg-secondary rounded-full w-full cursor-pointer',
        disabled && 'opacity-50 cursor-not-allowed',
        ['top', 'bottom'].includes(iconPosition) && 'flex-col',
        buttonClassName
      )}
      data-testid="button"
      disabled={disabled}
      {...props}
    >
      {isLoading && (
        <Icon
          name={IconName.Loader}
          size={spacing[4]}
          color={iconColor}
          className={twMerge('animate-spin', iconClassName)}
          data-testid="loading-spinner"
        />
      )}
      {icon && ['left', 'top'].includes(iconPosition) && (
        <div className={iconClassName}>{icon}</div>
      )}
      <div
        className={twMerge('text-white font-semibold text-sm', textClassName)}
      >
        {text}
      </div>
      {icon && ['right', 'bottom'].includes(iconPosition) && (
        <div className={iconClassName}>{icon}</div>
      )}
    </button>
  );
};

export default Button;
